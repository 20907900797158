<template>
  <div>
    <v-radio-group
      class="ml-2"
      v-model="computedType"
      :column="false"
    >
      <v-radio
        :label="$t(`t.Column`)"
        value="colId"
        class="mr-4"
      ></v-radio>
      <v-radio
        :label="$t(`t.Percent`)"
        value="percent"
      ></v-radio>
    </v-radio-group>
    <component
      :is="computedType"
      v-model="computedCalc"
      :target-doc-ty="targetDocTy"
    />
  </div>
</template>

<script>
export default {
  components: {
    colId: () => import('./stats-calc-col'),
    percent: () => import('./stats-calc-percent')
  },
  computed: {
    computedType: {
      get () {
        return this.dataCalc?.type
      },
      set (type) {
        this.computedCalc = Object.assign({}, this.computedCalc, { type })
      }
    },
    computedCalc: {
      get () { return this.dataCalc },
      set (v) {
        this.dataCalc = v
        this.dataDocumentEmit()
      }
    }
  },
  methods: {
    emitDocument () {
      if (!this.lodash.isEqual(this.value, this.dataCalc)) {
        this.$emit('input', this.lodash.cloneDeep(this.dataCalc))
      }
    }
  },
  data () {
    return {
      dataCalc: {},
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument)
    }
  },
  props: {
    value: Object,
    targetDocTy: String
  },
  watch: {
    value: {
      immediate: true,
      handler (v) {
        this.dataCalc = v ? this.lodash.cloneDeep(v) : { type: 'colId' }
        this.dataDocumentEmit()
      }
    }
  }
}
</script>
